export default function validateMemberForm(member) {
  // Ako je država HR, OIB je obvezan. U suprotnom, ID dokumenta je obvezan.

  if (member.MailingCountry == "Croatia" && !member?.Tax_Number__c) {
    return { validated: false, error: "OIB je obvezan" };
  }

  if (member.Tax_Number__c && !isOibValid(member.Tax_Number__c)) {
    return { validated: false, error: "OIB je neispravan" };
  }

  if (member.MailingCountry != "Croatia" && !member.ID_Number__c) {
    return { validated: false, error: "Broj dokumenta je obvezan" };
  }

  if (member.MailingCountry != "Croatia" && member.ID_Number__c.length < 1) {
    return { validated: false, error: "Broj dokumenta je neispravan" };
  }

  return { validated: true };
}

function isOibValid(input) {
  const oib = input.toString();

  if (oib.length !== 11 || oib.match(/\d{11}/) === null) {
    return false;
  }

  let calculated = 10;

  for (const digit of oib.substring(0, 10)) {
    calculated += parseInt(digit);

    calculated %= 10;

    if (calculated === 0) {
      calculated = 10;
    }

    calculated *= 2;
    calculated %= 11;
  }

  let check = 11 - calculated;

  if (check === 10) {
    check = 0;
  }

  return check === parseInt(oib[10]);
}
